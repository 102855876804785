<template>
  <Html
    :lang="head.htmlAttrs.lang"
    :dir="head.htmlAttrs.dir"
  >
    <Head>
      <Title>{{ title }}</Title>
    </Head>
    <Body :class="theme">
      <UiComponentWrapper id="app">
        <UiComponentWrapper class="app__layout">
          <slot />
        </UiComponentWrapper>
      </UiComponentWrapper>
    </Body>
  </Html>
</template>

<script setup>
// Composables
const { state } = useConditionalPreviewMode()
const { locale } = useI18n()
const { meta } = useRoute()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const theme = useState('color-scheme', () => 'light')

// Async Data
const { data } = await useAsyncData(
  `layout-screen-${locale.value}`,
  async () => {
    return Promise.all([
      $fetch(`/api/content/layouts/default/${locale.value}`, { query: { preview: state.preview } }),
      $fetch('/api/content/layouts/rating-schema/en'),
    ])
  },
  {
    transform: ([layoutData, ratingSchema]) => {
      const priceCurrency = locale.value === 'en' ? 'USD' : 'EUR'
      const proPlansCount = layoutData.componentPricing.proColumnPrice.length - 1
      return {
        ...layoutData,
        softwareApplicationSchema: {
          ...ratingSchema,
          applicationCategory: 'CommunicationApplication',
          offers: {
            '@context': 'https://schema.org/',
            '@type': 'AggregateOffer',
            priceCurrency,
            'lowPrice': layoutData.componentPricing.freeColumnPrice,
            'highPrice':
              priceCurrency === 'USD'
                ? layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly
                : layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly,
            'offeredBy': {
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              'name': 'Livestorm',
              'url': 'https://livestorm.co/',
            },
            'priceSpecification': [
              {
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price': layoutData.componentPricing.freeColumnPrice,
                priceCurrency,
                'name': layoutData.componentPricing.freeColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.freeColumnSubTitle,
                },
              },
              ...layoutData.componentPricing.proColumnPrice.map(data => ({
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price':
                  priceCurrency === 'USD'
                    ? data.priceUsdYearly
                    : data.priceEurYearly,
                priceCurrency,
                'name': layoutData.componentPricing.proColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.proColumnSubTitle.replace(
                    '%{currentActiveContacts}',
                    data.mac,
                  ),
                },
              })),
            ],
          },
        },
      }
    },
  },
)

// Computed Properties
const organisationSchema = computed(() => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'url': publicRuntimeConfig.baseUrl,
    'logo': data.value.global.logo.url,
  }
})

const title = computed(() => {
  return meta.title || 'Livestorm'
})

// SEO
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useSeoMeta({
  ogImage: data.value.openGraph.image.url,
})

// Structured Data
useLdJson(organisationSchema.value)
useLdJson(data.value.softwareApplicationSchema)
</script>

<style lang="postcss" scoped>
#app {
  @apply flex-col items-center h-screen w-screen;

  .app__layout {
    @apply flex-col items-center h-full w-full;
  }
}
</style>
